<template>
  <AppBar>
    <template slot="nav-item">
      <div>
        <el-button
          v-show="getRole_id == 1||getRole_id == 2||getRole_id == 6"
          type="primary"
          size="small"
          round
          @click="$router.replace({ name: 'ClientInfo' })"
        >发布新客户</el-button>
      </div>
    </template>
    <div class="content">
      <div
        class="box"
        v-for="item in dataClients"
        :key="item.customer_id"
        @click="onClickShowPop(item.customer_id)"
      >
        <el-image
          :src="
            item.thumb
              ? 'http://ghf.site.yscase.com' + item.thumb
              : '//cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
          "
          fit="cover"
        ></el-image>
        <h1 class="name">{{ item.name }}</h1>
      </div>

      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      background
      layout="total, prev, pager, next"
      :total="total"
    ></el-pagination>
    <el-dialog width="60%" :visible.sync="dialogFormVisible" @closed="closed">
      <div>
        <el-image
          :src="
            form.thumb
              ? `//ghf.site.yscase.com${form.thumb}`
              : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
          "
          fit="cover"
        ></el-image>
        <div v-if="compile && (getRole_id == 1 || getRole_id == 6)" class="operation">
          <el-button round @click="compile = false">编辑</el-button>
          <el-button round @click="remove(form.customer_id)">删除</el-button>
        </div>
        <div v-else-if="getRole_id == 1 || getRole_id == 6" class="operation">
          <el-button type="success" round @click="saveBtn">保存</el-button>
          <el-button type="danger" round @click="cancel">取消</el-button>
        </div>
      </div>
      <el-form :model="form" label-width="auto" :disabled="compile">
        <el-form-item label="公司名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="form.lxr"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" v-if="getRole_id == 1 || getRole_id == 2 || getRole_id == 6">
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="合作时机">
          <el-input v-model="form.hzsj"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :rows="3" resize="none" v-model="form.note"></el-input>
        </el-form-item>
      </el-form>
      <el-divider direction="vertical"></el-divider>
      <div class="information">
        <h6>客户的项目</h6>
        <p>
          该客户项目共计
          <b>{{ form.total }}</b>
          项
        </p>
        <ProjectSelectionList :list="myDatas" @screen="clientScreen"></ProjectSelectionList>
        <el-button type="info" round @click="examine(form.customer_id)">查看该客户的全部项目</el-button>
      </div>
    </el-dialog>
  </AppBar>
</template>

<style lang="less" scoped>
@deep: ~">>>";
.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 370px;
  }
  .box {
    width: 370px;
    height: 85px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 6px;
    border: #ebebeb 1px solid;
    //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    margin-top: 15px;
    margin-bottom: 15px;
    .el-image {
      width: 60px;
      height: 60px;
      margin-left: 20px;
      border-radius: 100%;
    }
    .name {
      margin-left: 20px;
      font-size: 20px;
    }
  }
}
@{deep}.el-dialog {
  min-width: 1000px;
  border-radius: 10px;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: -8px;
      right: -60px;
      font-size: 40px;
      .el-dialog__close {
        color: #eee;
      }
    }
  }
  .el-dialog__body {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .el-image {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      margin: 5% auto;
    }
    .operation {
      display: flex;
      justify-content: space-around;
    }
    .el-divider {
      height: 400px;
    }
    .information {
      width: 320px;
      flex-shrink: 0;
      h6 {
        font-size: 22px;
        font-weight: 400;
      }
      & > p {
        line-height: 60px;
        background-color: #ebebeb;
        text-align: center;
        font-size: 16px;
        border-radius: 10px;
        margin: 20px 10px 10px 0;
        b {
          margin: 0 5px;
          font-size: 24px;
          color: #f8b849;
        }
      }
    }
    .el-button {
      display: block;
      margin: 30px auto 0;
      font-size: 16px;
    }
  }
}
</style>
<script>
import AppBar from "../../components/AppBar.vue";
import ProjectSelectionList from "../../components/ProjectSelectionList";
export default {
  data() {
    return {
      getRole_id: sessionStorage.getItem("role_id"),
      compile: true,
      dataClients: {},
      dialogFormVisible: false,
      form: {
        company: "",
        linkman: "",
        contact: "",
        address: "",
        oppo: "",
        remark: "",
        hzsj: ""
      },
      form2: {},
      myDatas: [],
      currentPage: 1,
      total: 0
    };
  },
  watch: {
    compile(newValue) {
      // console.log(oldValue);

      this.$nextTick(() => {
        if (!newValue) {
          this.form2 = { ...this.form };
          // console.log(this.userData2);
        }
      });
    }
  },
  methods: {
    // 分页
    handleCurrentChange(page) {
      this.dataClients = [];
      this.$common.postInterface(
        "/api/v1.customer/getList",
        {
          page: page
        },
        this,
        res => {
          this.dataClients = res.data.data;
          this.total = res.data.total;
        }
      );
    },
    clientScreen(val) {
      let dataName = "";
      let data = "";
      if (val.name == "cnt_end") {
        dataName = "status";
        data = 3;
      } else if (val.name == "cnt_delay") {
        dataName = "is_delay";
        data = 1;
      } else if (val.name == "cnt_begin") {
        dataName = "status";
        data = 1;
      }
      this.examine(this.form.customer_id, dataName, data,val);
    },
    onClickShowPop: function(id) {
      this.$common.getInterface(
        "/api/v1.customer/getOne",
        {
          id: id
        },
        this,
        res => {
          this.form = res.data;
          this.myDatas = (() => {
            let { cnt_end, finish_per, cnt_delay, cnt_begin } = res.data;
            return { cnt_end, finish_per, cnt_delay, cnt_begin };
          })();
          this.dialogFormVisible = true;
        }
      );
    },
    closed() {
      this.compile = true;
    },
    remove(id) {
      this.$confirm("确定删除该客户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$common.postInterface(
            "/api/v1.customer/del",
            { id: id },
            this,
            res => {
              if (res.status === "200") {
                setmas(res.msg, "success", this);

                // this.$message({
                //   message: res.msg,
                //   type: "success"
                // });
                this.dialogFormVisible = false;
                this.getList();
              } else {
                setmas(res.msg, "warning", this);

                // this.$message({
                //   message: res.msg,
                //   type: "warning"
                // });
              }
            }
          );
        })
        .catch(() => {
          setmas("已取消删除", "info", this);

          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // });
        });
    },
    saveBtn() {
      this.$common.postInterface(
        "/api/v1.customer/edit",
        {
          name: this.form.name,
          id: this.form.customer_id,
          thumb: this.form.thumb ? this.form.thumb : "",
          address: this.form.address,
          lxr: this.form.lxr,
          tel: this.form.tel,
          note: this.form.note,
          hzsj: this.form.hzsj
        },
        this,
        res => {
          if (res.status === "200") {
            setmas(res.msg, "success", this);

            // this.$message({
            //   message: res.msg,
            //   type: "success"
            // });
            this.compile = true;
            this.getList();
          } else {
            setmas(res.msg, "warning", this);

            // this.$message({
            //   message: res.msg,
            //   type: "warning"
            // });
          }
          // this.userData = res.data;
          // console.log(res);
        }
      );
    },
    cancel() {
      this.compile = true;
      this.form = this.form2;
      // console.log(111);
    },
    examine(id, name, data,val) {
      // console.log(id, name, data);
      let Obj = {
        customer_id: id
      };
      if (name) {
        Obj[name] = data;
      }
      let item = {
        id,
        name,
        val,
      }
      sessionStorage.setItem("staffMsg", JSON.stringify(Obj));
      // this.$router.push({ path: `/index`});
      this.$router.push({ path: "/index", query: { id: item }});
    },
    getList() {
      this.$common.postInterface(
        "/api/v1.customer/getList",
        {
          page: "1"
        },
        this,
        res => {
          this.dataClients = res.data.data;
          this.total = res.data.total;
        }
      );
    }
  },
  mounted() {
    this.getList();
  },
  components: { AppBar, ProjectSelectionList }
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup"
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>